/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.App__alert {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 1001;
}
.App__layout {
  margin: 0 auto;
  max-width: 1200px;
}
.App__layout--fullWidth {
  max-width: none;
}
.App__header {
  line-height: 30px;
  padding: 35px 0;
}
.App__content {
  padding-top: 5px;
  padding-bottom: 48px;
  padding-left: 48px;
  padding-right: 48px;
}
@media (max-width: 576px) {
  .App__content {
    padding-top: 0px;
    padding-left: 16px;
    padding-right: 16px;
  }
}
